/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router';
import VehicleService from 'src/services/Vehicle.service';
import QRCode from 'qrcode';
import DateTimeService from 'src/utils/DateTimeService';
import OrganisationService from 'src/services/Organisation.service';
import useAuth from 'src/hooks/useAuth';
import AppConfig from 'src/flavours/index.flavour';
import CertificateService from 'src/services/Certificate.service';
import ComponentLoader from '../ComponentLoader';
import CertificateSectionLayout from './CertificateSectionLayout';
import certificateDataFormat from './CertificateData';
import WarrantyCertificateFormat from './WarrantyData';
import ImageSectionLayout from './ImageSectionLayout';
import { handleExportCertificatePdf } from './certificatePdf';

const CertificateLayout = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const currentURL = window.location.href;

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const [warrantyData, setWarrantyData] = useState(null);
  const [WarrantyLayoutData, setWarrantyLayoutData] = useState(null);

  const [certificateLayoutData, setCertificateLayoutData] = useState(null);
  const [organisationLogo, setOrganisationLogo] = useState(
    `/static/images/logos/${AppConfig.id}.png`
  );

  const [loading, setLoading] = useState(false);

  Font.register({
    family: 'BoldOpenSans',
    src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf'
  });

  const getQuickCertificate = async () => {
    setLoading(true);
    try {
      const { data } = await CertificateService.getQuickCertificateData({
        imei: id
      });
      setCertificateData(data);
      setCertificateLayoutData(certificateDataFormat(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getWarranty = async () => {
    setLoading(true);
    try {
      const {
        data: [data]
      } = await VehicleService.getPublicVehicleDetails({
        imei: id,
        hydrate: 'device,owner'
      });
      console.log(data);
      setWarrantyData(data);
      setWarrantyLayoutData(WarrantyCertificateFormat(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuickCertificate();
    getWarranty();
    // getOrganisationLogo();
    QRCode.toDataURL(currentURL, (err, url) => {
      if (err) throw err;
      setQrCodeDataUrl(url);
    });
  }, [id]);

  const styles = StyleSheet.create({
    vehicleTable: {
      display: 'table',
      width: 'auto',
      borderStyle: 'none',
      fontSize: '8px',
      flexGrow: 7
    },
    heading2: {
      marginTop: '10px',
      fontSize: '12px',
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      textAlign: 'left' // This aligns the text to the left
    },
    heading3: {
      paddingTop: '20px',
      marginTop: '20px',
      fontSize: '12px',
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      textAlign: 'left' // This aligns the text to the left
    },
    footerText2: {
      paddingTop: '20px',
      marginTop: '42px',
      fontSize: '10px',
      lineHeight: '1.5em'
    },
    text2: {
      paddingTop: '5px',
      // marginTop: '5px',
      lineHeight: '1.5px',
      fontSize: '10px'
    },

    text3: {
      paddingTop: '20px',
      marginTop: '20px',
      lineHeight: '1.5px',
      fontSize: '10px'
    },
    TopBottom: {
      marginTop: '20px',
      marginBottom: '20px'
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'none',
      fontSize: '8px'
    },
    TextTop: {
      marginTop: '20px'
    },
    row: {
      flexDirection: 'row'
    },
    cell: {
      flexGrow: 1,
      padding: 5,
      width: '25%',
      overflow: 'hidden'
    },
    imageCell: {
      flexGrow: 1,
      margin: 5,
      width: '33.33%',
      overflow: 'hidden',
      borderRadius: '5px',
      border: '1px solid gray'
    },
    header: {
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      marginBottom: '3px'
    },
    paper: {
      margin: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    customImage: {
      width: '90px',
      height: '90px',
      objectFit: 'contain',
      marginBottom: '5px'
    },
    listContainer: {
      marginTop: 10,
      marginLeft: 28
      // Add other styling as needed
    },
    listItem: {
      lineHeight: '1.5px',
      fontSize: '10px',
      margin: '0 5px 5 5px'
      // Add other styling as needed
    },

    imageBlock: {
      paddingTop: '50px'
    },
    imageRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Center images horizontally
      alignItems: 'center',
      // margin: '5px 10px',
      paddingLeft: '5px',
      paddingRight: '5px',

      // width: '100%',
      // gap: 2, // Spacing between rows
      marginTop: '30px'

      // Optional: add padding if needed
    },
    image: {
      width: '300px', // Increase size of each image
      height: '150px', // Increase size of each image
      objectFit: 'cover',
      borderColor: '#ddd'
      // paddingTop:'50px', // Ensure image is visible
      // Spacing between images
    },

    grid: {
      borderBottom: '1px solid #ffffff',
      margin: '10px 0 0 10px',
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      flexWrap: 'wrap',
      gap: '20px'
    },
    grid2: {
      margin: '8px 0 0 10px',
      padding: '8px',
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      flexWrap: 'wrap',
      gap: '15px'
    },

    textSection: {
      marginLeft: '10px'
    },
    heading: {
      fontSize: '12px',
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      width: '100%',
      marginBottom: '10px'
    },
    title: {
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold'
    },
    qrCode: {
      textAlign: 'center'
    },
    footerImage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      marginBottom: 20, // Add space between rows
      fontSize: 8,
      marginLeft: 0, // Remove left margin
      marginRight: 0,
      paddingHorizontal: 10 // Remove right margin
    },
    footerText: {
      lineHeight: '1.5px',
      fontSize: '8px',
      margin: '5px 10px',
      padding: '5px'
    },
    headerLayout: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justifyItems: 'space-between',
      alignItems: 'center'
    },
    card: {
      alignItems: 'center',
      padding: 0, // Remove internal padding in the card
      borderRadius: 4,
      backgroundColor: '#f6f6f6',
      borderWidth: 1, // Ensure the border is visible
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      borderColor: '#fff',
      paddingRight: '3px',
      paddingLeft: '3px',
      paddingBottom: '3px',
      shadowRadius: 2,
      elevation: 5,
      marginHorizontal: 10,
      margin: 0 // Remove margin around the card to eliminate gaps between images
    },
    rowtop: {
      marginTop: '28px',
      display: 'flex',
      flexDirection: 'row',

      paddingRight: '20px'
    },
    imageNameBox: {
      textAlign: 'center',
      marginTop: '5px' // Space between image and name
    },

    imageName: {
      fontSize: '10px',
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold'
    },

    childComponent: {
      marginLeft: '10px'
    }
  });

  const MyDocument = () => {
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState(null);

    const PdfDataLayout = ({ data }) => {
      return (
        <View style={styles.table}>
          {data
            ?.filter((item) => item?.accessor !== (null || undefined))
            .reduce((result, item, index) => {
              if (index % 4 === 0) result.push([]);
              result[result.length - 1].push(item);
              return result;
            }, [])
            .map((chunk) => (
              <View style={styles.row}>
                {chunk.map((item) => (
                  <View style={styles.cell}>
                    <Text style={styles.header}>{item?.header}</Text>
                    <Text>{item?.accessor}</Text>
                  </View>
                ))}
                {Array(4 - chunk.length)
                  .fill(null)
                  .map(() => (
                    <View style={styles.cell} />
                  ))}
              </View>
            ))}
        </View>
      );
    };

    const PdfImageDataLayout = ({ data }) => {
      // const url = data?.accessor;
      return (
        <View style={styles.table}>
          {data
            ?.filter((item) => !!item?.accessor)
            .reduce((result, item, index) => {
              if (index % 3 === 0) result.push([]);
              result[result.length - 1].push(item);
              return result;
            }, [])
            .map((chunk) => (
              <View style={styles.row}>
                {chunk.map((item) => (
                  <View style={styles.imageCell}>
                    <Image
                      src={item?.accessor}
                      style={{
                        width: '100px',
                        height: 200,
                        objectFit: 'contain'
                      }}
                    />
                  </View>
                ))}
                {Array(3 - chunk.length)
                  .fill(null)
                  .map(() => (
                    <View style={styles.imageCell} />
                  ))}
              </View>
            ))}
        </View>
      );
    };

    const PdfSectionLayout = ({ _certificateData }) => {
      return (
        <>
          {_certificateData?.data?.length > 0 && (
            <View style={styles.grid}>
              <Text style={styles.heading}>{_certificateData?.header}</Text>

              <PdfDataLayout data={_certificateData?.data} />
            </View>
          )}
        </>
      );
    };

    const PdfImageSectionLayout = ({ _certificateData }) => {
      return (
        <>
          {_certificateData?.data?.length > 0 && (
            <View style={styles.grid}>
              <Text style={styles.heading}>{_certificateData?.header}</Text>

              <PdfImageDataLayout data={_certificateData?.data} />
            </View>
          )}
        </>
      );
    };

    useEffect(() => {
      // getOrganisationLogo();
      QRCode.toDataURL(currentURL, (err, url) => {
        if (err) throw err;
        setQrCodeDataUrl(url);
      });
    }, []);

    return (
      <>
        <Document>
          <Page>
            <View style={styles.paper}>
              <View style={styles.headerLayout}>
                <View style={styles.childComponent}>
                  <Image
                    style={styles.customImage}
                    src={organisationLogo}
                    alt="Logo"
                  />
                </View>

                <View style={styles.childComponent}>
                  <Text style={styles.title}>VLTD fitment Certificate</Text>
                </View>
                <View style={styles.qrCode}>
                  <Image style={styles.customImage} src={qrCodeDataUrl} />
                </View>
              </View>

              {certificateLayoutData
                ?.filter((d) => d?.type !== 'Image')
                ?.map((item) => (
                  <PdfSectionLayout _certificateData={item} />
                ))}
              {certificateLayoutData?.filter(
                (d) => d?.data?.length > 0 && d?.type === 'Image'
              )?.length === 0 && (
                <Text style={styles.footerText}>
                  Disclaimer: We hereby acknowledge the installation of a
                  GPS/VTS/VLTD device, provided by {AppConfig?.fullName} with
                  IMEI No.
                  {certificateData?.device?.imei} and model{' '}
                  {certificateData?.device?.model}, on our vehicle registered
                  under number &nbsp;
                  {certificateData?.vechicle?.registrationNumber}. Subsequent to
                  the installation, we conducted a thorough assessment of the
                  vehicle's performance and are pleased to affirm that the
                  device is operating in compliance with the standards
                  stipulated in AIS140 by ARAI/ICAT. Our satisfaction extends
                  across all operational aspects of the unit. We wish to assert
                  our commitment to refrain from initiating any disputes or
                  legal claims against {AppConfig?.fullName} in the event that
                  the aforementioned seals, device, and harness display any
                  signs of tampering or breakage within the 12-month warranty
                  period from the date of installation. It is imperative to note
                  that any and all disputes arising shall be subjected solely to
                  Kolkata jurisdiction only. *
                </Text>
              )}
            </View>
          </Page>
          {certificateLayoutData?.filter(
            (d) => d?.data?.length > 0 && d?.type === 'Image'
          )?.length > 0 && (
            <Page>
              <View style={styles.paper}>
                {certificateLayoutData
                  ?.filter((d) => d?.type === 'Image')
                  ?.map((item) => (
                    <PdfImageSectionLayout _certificateData={item} />
                  ))}
                <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
                  DISCLAIMER
                </Typography>
                <Text style={styles.footerText}>
                  We hereby acknowledge the installation of a GPS/VTS/VLTD
                  device, provided by {AppConfig?.fullName} with IMEI No.
                  {certificateData?.device?.imei} and model{' '}
                  {certificateData?.device?.model}, on our vehicle registered
                  under number &nbsp;
                  {certificateData?.vechicle?.registrationNumber}. Subsequent to
                  the installation, we conducted a thorough assessment of the
                  vehicle's performance and are pleased to affirm that the
                  device is operating in compliance with the standards
                  stipulated in AIS140 by ARAI/ICAT. Our satisfaction extends
                  across all operational aspects of the unit. We wish to assert
                  our commitment to refrain from initiating any disputes or
                  legal claims against {AppConfig?.fullName} in the event that
                  the aforementioned seals, device, and harness display any
                  signs of tampering or breakage within the 12-month warranty
                  period from the date of installation. It is imperative to note
                  that any and all disputes arising shall be subjected solely to
                  Kolkata jurisdiction only. *
                </Text>
              </View>
            </Page>
          )}
          <Page>
            <View style={styles.paper}>
              <View style={styles.headerLayout}>
                <View style={styles.childComponent}>
                  <Image
                    style={styles.customImage}
                    src={organisationLogo}
                    alt="Logo"
                  />
                </View>

                <View style={styles.childComponent}>
                  <Text style={styles.title}>Warranty Certificate</Text>
                </View>
                <View style={styles.qrCode}>
                  <Image style={styles.customImage} src={qrCodeDataUrl} />
                </View>
              </View>

              {WarrantyLayoutData?.map((item) => (
                <PdfSectionLayout _certificateData={item} />
              ))}
              <View style={styles.grid2}>
                <Text style={styles.heading2}>TERMS for Warranty</Text>

                <Text style={styles.text2}>
                  TRAXSMART (herein after called “TRAXSMART”) guarantees to you,
                  the original Purchaser, the tracker ”TSINDIA140” (this
                  warranty and its clauses apply to all trackers manufactured by
                  TRAXSMART unless stated separately ), to be in conformance
                  with the applicable TSINDIA140 Specifications current at the
                  time of Manufacture. Purchase Date of the First Buyer will be
                  the Warranty Start Date. Valid for 12 months for the Tracker
                  and accessories. You must inform TRAXSMART immediately of any
                  of the Tracker which has been found defective.
                </Text>
              </View>
              <View style={styles.grid2}>
                <Text style={styles.heading2}>CLAIM</Text>
                <Text style={styles.text2}>
                  For Claiming the Warranty of the TSINDIA140 Device , In case
                  the device is Fitted to Vehicle ,Firstly it should be driven
                  to nearest Authorised Repair or Service Centre for analysis of
                  the Device after the testing and verification , The device
                  should be sent to 21 Princep street 3rd floor Kolkata-700072{' '}
                  {certificateData?.owner?.firstName}{' '}
                  {certificateData?.owner?.lastName} ,{' '}
                  {certificateData?.owner?.phone} ,{' '}
                  {certificateData?.owner?.email} ,
                  {certificateData?.owner?.address} , Proof of Purchase Copy
                  incase of warranty claim. If the TSINDIA140 device is found
                  faulty before the fitment ,it should be sent to 21 Princep
                  street 3rd floor Kolkata-700072 ( In case of sold ) with{' '}
                  {certificateData?.owner?.firstName}{' '}
                  {certificateData?.owner?.lastName} ,{' '}
                  {certificateData?.owner?.phone} ,{' '}
                  {certificateData?.owner?.email} ,
                  {certificateData?.owner?.address} , Proof of Purchase Copy
                  having date of Invoice incase of warranty claim.All should be
                  returned duly with all packaging and / or accessories as
                  supplied by TSINDIA140.
                </Text>
              </View>
            </View>
          </Page>
          <Page>
            <View style={styles.paper}>
              <View style={styles.grid2}>
                <Text style={styles.heading2}>
                  WHAT IS NOT COVERED BY THE WARRANTY
                </Text>
                <Text style={styles.text2}>
                  Warranty are not covered for the defects are due to damage,
                  misuse, tampering, neglect or in case of alterations / Changes
                  or repairs done by unauthorized persons / Company.
                  {'\n'}
                  {'\n'}
                  The following are Points Not Covered
                </Text>
              </View>

              <View style={styles.listContainer}>
                <Text style={styles.listItem}>
                  {' '}
                  1) Defects or damage due to abnormal usage or non standard
                  environments .
                </Text>
                <Text style={styles.listItem}>
                  {' '}
                  2) Defects or damage from misuse, accident or intentional
                  damage.
                </Text>
                <Text style={styles.listItem}>
                  {' '}
                  3) Defects or damage from due to improper testing and usage by
                  non authorized applications or operations or modifications
                  done to TSINDIA140 device.
                </Text>
                <Text style={styles.listItem}>
                  4) Tamper Seal is broken / Box opened by unauthorized person..
                </Text>
                <Text style={styles.listItem}>
                  5) Breakage or alterations to Device due to excessive force or
                  other means.
                </Text>
                <Text style={styles.listItem}>
                  6) TSINDIA140 Device disassembled or repaired other than by
                  TRAXSMART .
                </Text>
                <Text style={styles.listItem}>
                  {' '}
                  7) TSINDIA140 Device exposed to conditions not specified or
                  warranted environment or breaching the limits of the stated.
                </Text>
                <Text style={styles.listItem}>
                  {' '}
                  8) Accessories, software applications and peripherals
                  (specific examples include but are not limited to batteries,
                  charges, adapters, and power supplies) when such accessories,
                  software applications and peripherals are not manufactured and
                  supplied by TSINDIA140.
                </Text>
              </View>

              <View style={styles.grid2}>
                <Text style={styles.heading3}>CONDITIONS</Text>
                <Text style={styles.text3}>
                  This warranty will not apply if the type or serial numbers on
                  the Tracker have been altered, deleted, duplicated, removed or
                  made illegible. TSINDIA140 reserves the right to refuse free-
                  of- charge warranty service. If the requested documentation
                  cannot be presented or if the information is incomplete,
                  illegible or incompatible with the factory records. Repair at
                  TSINDIA140's option, may include reflashing of software, the
                  replacement of parts or boards with functionally equivalent ,
                  reconditioned or new parts or boards. Replaced parts,
                  accessories, batteries, or boards are warranted for the
                  balance of the original warranted tie period. The warranty
                  Term will not be extended.
                </Text>

                <Text style={styles.text2}>
                  All original accessories, batteries, parts, and tracker
                  equipment that have been replaced shall become the property of
                  TSINDIA140. TSINDIA140 does not warrant the installation,
                  maintenance or service of the products,accessories, batteries,
                  or parts. TSINDIA140 will not be responsible in any way for
                  problems or damage caused by any ancillary equipment not
                  furnished by TSINDIA140 which is attached to or used in
                  connection with the Tracker or for operation of TSINDIA140
                  equipment with any ancillary equipment and all such equipment
                  is expressly from this warranty, when the tracker is used in
                  conjunction with ancillary or peripheral equipment not
                  supplied by TSINDIA140. TSINDIA140 does not warrant the
                  operation of the tracker / peripheral combination and
                  TSINDIA140 will not honour any warranty claim where the
                  Tracker is used in such a combination and it is determined by
                  TSINDIA140 that there is no fault with the Tracker. TSINDIA140
                  specifically disclaims any responsibility for any damage,
                  whether or not to TRAXSMART equipment, caused in any way by
                  the use of the Tracker,
                </Text>
              </View>

              <br />
              <br />
              <View style={styles.grid2}>
                <Text style={styles.heading2}>
                  REPAIRS and SERVICES for out of Warranty
                </Text>
                <Text style={styles.text2}>
                  TRAXSMART ,TSINDIA140 will repair tracking device any time
                  after the warranty terms of where this warranty does not apply
                  due to the defect or fault , then TRAXSMART may at its
                  discretion carry out such repairs subject to cost to be borne
                  by Customer as specified by TRAXSMART . 21 princep steet 3rd
                  floor Kolkata-700072
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </>
    );
  };

  return (
    <>
      {loading && <ComponentLoader />}
      {!loading && certificateData && (
        <Paper
          sx={{
            m: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          elevation={1}
        >
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ ml: '1em' }}>
              <img
                style={{
                  maxWidth: '12em',
                  maxHeight: '12em',
                  objectFit: 'contain'
                }}
                src={organisationLogo}
                alt="Logo"
              />
            </Box>
            <Typography variant="h3">VLTD Fitment Certificate</Typography>
            <Box>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
                src={qrCodeDataUrl}
                alt="qr code"
              />
            </Box>
          </Stack>

          {certificateLayoutData?.map(
            (item) =>
              item?.data?.length > 0 && (
                <>
                  <Grid
                    container
                    sx={{
                      borderBottom: '1px solid #cfcfcf',
                      my: '1em',
                      overflow: 'hidden',
                      maxWidth: '95%',
                      p: '2em'
                    }}
                    spacing={2}
                  >
                    <Grid md={12}>
                      <Typography sx={{ mb: '0.5em' }} variant="h4">
                        {item?.header}
                      </Typography>
                    </Grid>

                    <Grid md={12}>
                      {item?.type === 'Image' ? (
                        <ImageSectionLayout data={item?.data} />
                      ) : (
                        <CertificateSectionLayout data={item?.data} />
                      )}
                    </Grid>
                  </Grid>
                </>
              )
          )}

          {/* {JSON.stringify(images)} */}
          {/* FIXME: change the model name, its hardcoded */}

          <View style={styles.grid2}>
            <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
              Disclaimer:
            </Typography>
            <Typography sx={{ ml: '1em', mt: '1em' }}>
              We hereby acknowledge the installation of a GPS/VTS/VLTD device,
              provided by {AppConfig?.fullName} with IMEI No.
              {certificateData?.device?.imei} and model{' '}
              {certificateData?.device?.model}, on our vehicle registered under
              number &nbsp;
              {certificateData?.vechicle?.registrationNumber}. Subsequent to the
              installation, we conducted a thorough assessment of the vehicle's
              performance and are pleased to affirm that the device is operating
              in compliance with the standards stipulated in AIS140 by
              ARAI/ICAT. Our satisfaction extends across all operational aspects
              of the unit. We wish to assert our commitment to refrain from
              initiating any disputes or legal claims against{' '}
              {AppConfig?.fullName} in the event that the aforementioned seals,
              device, and harness display any signs of tampering or breakage
              within the 12-month warranty period from the date of installation.
              It is imperative to note that any and all disputes arising shall
              be subjected solely to Kolkata jurisdiction only. *
            </Typography>
          </View>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ ml: '1em' }}>
              <img
                style={{
                  maxWidth: '12em',
                  maxHeight: '12em',
                  objectFit: 'contain'
                }}
                src={organisationLogo}
                alt="Logo"
              />
            </Box>

            <Text variant="h3">Warranty Certificate</Text>
            <Box>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
                src={qrCodeDataUrl}
                alt="qr code"
              />
            </Box>
          </Stack>

          {WarrantyLayoutData?.map((item) => (
            <>
              <Grid
                container
                sx={{
                  borderBottom: '1px solid #cfcfcf',
                  my: '1em',
                  overflow: 'hidden',
                  maxWidth: '95%',
                  p: '2em'
                }}
                spacing={2}
              >
                <Grid md={12}>
                  <Text sx={{ mb: '0.5em' }} variant="h4">
                    {item?.header}
                  </Text>
                </Grid>

                <Grid md={12}>
                  <CertificateSectionLayout data={item?.data} />
                </Grid>
              </Grid>
            </>
          ))}
          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            Terms and Warranty
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            TRAXSMART (herein after called “TRAXSMART”) guarantees to you, the
            original Purchaser, the tracker ”TSAIS140” (this warranty and its
            clauses apply to all trackers manufactured by TRAXSMART unless
            stated separately ), to be in conformance with the applicable
            TSINDIA140 Specifications current at the time of Manufacture
            <br /> <br /> Purchase Date of the First Buyer will be the Warranty
            Start Date.
            <br /> <br /> Valid for 12 months for the Tracker and accessories.
            <br /> <br /> You must inform TRAXSMART immediately of any of the
            Tracker which has been found defective.
          </Typography>
          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            CLAIMS
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            For Claiming the Warranty of the TSINDIA140 Device, In case the
            device is fitted to Vehicle, Firstly it should be driven to nearest
            Authorised Repair or Service Centre for analysis of the Device after
            the testing and verification, The device should be sent to TRAXSMART
            ofice 21 Princep street 3rd floor Kolkata-700072 with{' '}
            {certificateData?.owner?.firstName}{' '}
            {certificateData?.owner?.lastName}, {certificateData?.owner?.phone},
            {certificateData?.owner?.email}, {certificateData?.owner?.address},
            Proof of Purchase Copy incase of warranty claim.
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            If the TSINDIA140 device is found faulty before the fitment ,it
            should 21 Princep street 3rd floor Kolkata-700072 ( In case of sold
            ) with {certificateData?.owner?.firstName}{' '}
            {certificateData?.owner?.lastName} ,{certificateData?.owner?.phone}{' '}
            , {certificateData?.owner?.email} ,{certificateData?.owner?.address}{' '}
            , Proof of Purchase Copy having date of Invoice incase of warranty
            claim. All should be returned duly with all packaging and / or
            accessories as supplied by TSINDIA140.
          </Typography>
          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            WHAT IS NOT COVERED BY THE WARRANTYS
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            Warranty are not covered for the defects are due to damage, misuse,
            tampering, neglect or in case of alterations / Changes or repairs
            done by unauthorized persons / Company.
            <br />
            The following are Points Not Covered
            <br />
            1) Defects or damage due to abnormal usage or non standard
            environments .<br />
            2) Defects or damage from misuse, accident or intentional damage.
            <br />
            3) Defects or damage from due to improper testing and usage by non
            authorized applications or operations or modifications done to
            TSINDINA140 device.
            <br />
            4) Tamper Seal is broken / Box opened by unauthorized person.
            <br />
            5) Breakage or alterations to Device due to excessive force or other
            means.
            <br />
            6)TSINDIA140 Device disassembled or repaired other than by TRAXSMART
            .<br />
            7)TSINDIA140 Device exposed to conditions not specified or warranted
            environment or breaching the limits of the stated. <br />
            8) Accessories, software applications and peripherals (speciﬁc
            examples include but are not limited to batteries, charges,
            adapters, and power supplies) when such accessories, software
            applications and peripherals are not manufactured and supplied by
            TSINDIA140. CONDITIONS
            <br />
          </Typography>

          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            CONDITIONS
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            This warranty will not apply if the type or serial numbers on the
            Tracker have been altered, deleted, duplicated, removed or made
            illegible. TSINDIA140 reserves the right to refuse free- of- charge
            warranty service. If the requested documentation cannot be presented
            or if the information is incomplete, illegible or incompatible with
            the factory records. Repair at TSINDIA140’s option, may include of
            software, the replacement of parts or boards with functionally
            equivalent , reconditioned or new parts or boards. Replaced parts,
            accessories, batteries, or boards are warranted for the balance of
            the original warranted tie period. The warranty Term will not be
            extended. All original accessories, batteries, parts, and tracker
            equipment that have been replaced shall become the property of
            TSINDIA140. TSINDIA140 does not warrant the installation,
            maintenance or service of the products,accessories, batteries, or
            parts. TSINDIA140 will not be responsible in any way for problems or
            damage caused by any ancillary equipment not furnished by TSINDIA140
            which is attached to or used in connection with the Tracker or for
            operation of TSINDIA140 equipment with any ancillary equipment and
            all such equipment is expressly from this warranty, when the tracker
            is used in conjunction with ancillary or peripheral equipment not
            supplied by TSINDIA140. TSINDIA140 does not warrant the operation of
            the tracker / peripheral combination and TSINDIA140 will not honour
            any warranty claim where the Tracker is used in such a combination
            and it is determined by TSINDIA140 that there is no fault with the
            Tracker. TSINDIA140 specifically disclaims any responsibility for
            any damage, whether or not to TRAXSMART equipment, caused in any way
            by the use of the Tracker.
          </Typography>

          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            REPAIRS and SERVICES for out of Warranty
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            TRAXSMART ,TSINDIA140 will repair tracking device any time after the
            warranty terms of where this warranty does not apply due to the
            defect or fault , then TRAXSMART may at its discretion carry out
            such repairs subject to cost to be borne by Customer as specified by
            TRAXSMART .
          </Typography>
          <Typography sx={{ fontSize: '2em', fontWeight: 'bold' }}>
            TRAXSMART
          </Typography>
          <Typography sx={{ ml: '1em', mt: '1em' }}>
            21 Princep Street 3rd floor Kolkata-700072
          </Typography>

          {/* <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${
              certificateData?.vehicle?.registrationNumber
            }-VLTD_Certificate-${DateTimeService.getDateString(
              certificateData?.device?.fitment?.date
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              console.log('error', error, blob, url);
              return (
                <Button sx={{ my: '2em' }} variant="contained">
                  {loading ? 'Loading document...' : 'Print'}
                </Button>
              );
            }}
          </PDFDownloadLink> */}
          <Button
            variant="contained"
            onClick={() =>
              handleExportCertificatePdf(
                certificateData,
                certificateLayoutData,
                WarrantyLayoutData,
                qrCodeDataUrl
              )
            }
            disabled={loading}
          >
            {loading ? 'Loading document...' : 'Print'}
          </Button>
        </Paper>
      )}
    </>
  );
};

export default CertificateLayout;
