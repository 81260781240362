import React, { useEffect, useState } from 'react';
import AppConfig from 'src/flavours/index.flavour';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  pdf,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import saveAs from 'file-saver';
import DateTimeService from 'src/utils/DateTimeService';

const styles = StyleSheet.create({
  vehicleTable: {
    display: 'table',
    width: 'auto',
    borderStyle: 'none',
    fontSize: '8px',
    flexGrow: 7
  },
  heading2: {
    marginTop: '10px',
    fontSize: '12px',
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold',
    textAlign: 'left' // This aligns the text to the left
  },
  heading3: {
    paddingTop: '20px',
    marginTop: '20px',
    fontSize: '12px',
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold',
    textAlign: 'left' // This aligns the text to the left
  },
  footerText2: {
    paddingTop: '20px',
    marginTop: '42px',
    fontSize: '10px',
    lineHeight: '1.5em'
  },
  text2: {
    paddingTop: '5px',
    // marginTop: '5px',
    margin: '5px 5px 0 5px',
    lineHeight: '1.5px',
    fontSize: '10px'
  },

  text3: {
    paddingTop: '20px',
    marginTop: '20px',
    lineHeight: '1.5px',
    fontSize: '10px'
  },
  TopBottom: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'none',
    fontSize: '8px'
  },
  TextTop: {
    marginTop: '20px'
  },
  row: {
    flexDirection: 'row'
  },
  cell: {
    flexGrow: 1,
    padding: 5,
    width: '25%',
    overflow: 'hidden'
  },
  imageCell: {
    flexGrow: 1,
    margin: 5,
    width: '33.33%',
    overflow: 'hidden',
    borderRadius: '5px',
    aspectRatio: 1.33,
    border: '1px solid gray'
  },
  header: {
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold',
    marginBottom: '3px'
  },
  paper: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  customImage: {
    width: '75px',
    height: '75px',
    objectFit: 'contain',
    marginBottom: '5px'
  },
  listContainer: {
    marginTop: 10,
    marginLeft: 28
    // Add other styling as needed
  },
  listItem: {
    lineHeight: '1.5px',
    fontSize: '10px',
    margin: '0 5px 5px 5px'
    // Add other styling as needed
  },

  imageBlock: {
    paddingTop: '50px'
  },
  imageRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center', // Center images horizontally
    alignItems: 'center',
    // margin: '5px 10px',
    paddingLeft: '5px',
    paddingRight: '5px',

    // width: '100%',
    // gap: 2, // Spacing between rows
    marginTop: '30px'

    // Optional: add padding if needed
  },
  image: {
    width: '300px', // Increase size of each image
    height: '150px', // Increase size of each image
    objectFit: 'cover',
    borderColor: '#ddd'
    // paddingTop:'50px', // Ensure image is visible
    // Spacing between images
  },

  grid: {
    borderBottom: '1px solid #bbbbbb',
    margin: '10px 0 0 10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    flexWrap: 'wrap',
    gap: '20px'
  },
  grid2: {
    margin: '8px 0 0 10px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    flexWrap: 'wrap',
    gap: '15px'
  },

  textSection: {
    marginLeft: '10px'
  },
  heading: {
    fontSize: '12px',
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '10px'
  },
  title: {
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold'
  },
  qrCode: {
    textAlign: 'center'
  },
  footerImage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    marginBottom: 20, // Add space between rows
    fontSize: 8,
    marginLeft: 0, // Remove left margin
    marginRight: 0,
    paddingHorizontal: 10 // Remove right margin
  },
  footerText: {
    lineHeight: '1.5px',
    fontSize: '8px',
    margin: '5px 10px',
    padding: '5px'
  },
  headerLayout: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'center'
  },
  card: {
    alignItems: 'center',
    padding: 0, // Remove internal padding in the card
    borderRadius: 4,
    backgroundColor: '#f6f6f6',
    borderWidth: 1, // Ensure the border is visible
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    borderColor: '#fff',
    paddingRight: '3px',
    paddingLeft: '3px',
    paddingBottom: '3px',
    shadowRadius: 2,
    elevation: 5,
    marginHorizontal: 10,
    margin: 0 // Remove margin around the card to eliminate gaps between images
  },
  rowtop: {
    marginTop: '28px',
    display: 'flex',
    flexDirection: 'row',

    paddingRight: '20px'
  },
  imageNameBox: {
    textAlign: 'center',
    marginTop: '5px' // Space between image and name
  },

  imageName: {
    fontSize: '10px',
    fontFamily: 'BoldOpenSans',
    fontWeight: 'bold'
  },

  childComponent: {
    marginLeft: '10px'
  }
});

const PdfDataLayout = ({ data }) => {
  return (
    <View style={styles.table}>
      {data
        ?.filter((item) => item?.accessor !== (null || undefined))
        .reduce((result, item, index) => {
          if (index % 4 === 0) result.push([]);
          result[result.length - 1].push(item);
          return result;
        }, [])
        .map((chunk) => (
          <View style={styles.row}>
            {chunk.map((item) => (
              <View style={styles.cell}>
                <Text style={styles.header}>{item?.header}</Text>
                <Text>{item?.accessor}</Text>
              </View>
            ))}
            {Array(4 - chunk.length)
              .fill(null)
              .map(() => (
                <View style={styles.cell} />
              ))}
          </View>
        ))}
    </View>
  );
};

const PdfImageDataLayout = ({ data }) => {
  return (
    <View style={styles.table}>
      {data
        ?.filter((item) => !!item?.accessor)
        .reduce((result, item, index) => {
          if (index % 3 === 0) result.push([]);
          result[result.length - 1].push(item);
          return result;
        }, [])
        .map((chunk) => (
          <View style={styles.row}>
            {chunk.map((item) => (
              <View style={styles.imageCell}>
                <Image
                  src={item?.accessor}
                  // style={{
                  //   width: '100px',
                  //   height: 200,
                  //   objectFit: 'contain'
                  // }}
                />
              </View>
            ))}
          </View>
        ))}
    </View>
  );
};

const PdfImageSectionLayout = ({ _certificateData }) => {
  return (
    <>
      {_certificateData?.data?.length > 0 && (
        <View style={styles.grid}>
          <Text style={styles.heading}>{_certificateData?.header}</Text>

          <PdfImageDataLayout data={_certificateData?.data} />
        </View>
      )}
    </>
  );
};

const PdfSectionLayout = ({ _certificateData }) => {
  return (
    <>
      {_certificateData?.data?.length > 0 && (
        <View style={styles.grid}>
          <Text style={styles.heading}>{_certificateData?.header}</Text>

          <PdfDataLayout data={_certificateData?.data} />
        </View>
      )}
    </>
  );
};

const createCertificate = (
  certificateData,
  certificateLayoutData,
  WarrantyLayoutData,
  qrCodeDataUrl,
  organisationLogo
) => (
  <Document>
    <Page>
      <View style={styles.paper}>
        <View style={styles.headerLayout}>
          <View style={styles.childComponent}>
            <Image
              style={styles.customImage}
              src={organisationLogo}
              alt="Logo"
            />
          </View>

          <View style={styles.childComponent}>
            <Text style={styles.title}>VLTD fitment Certificate</Text>
          </View>
          <View style={styles.qrCode}>
            <Image style={styles.customImage} src={qrCodeDataUrl} />
          </View>
        </View>

        {certificateLayoutData
          ?.filter((d) => d?.type !== 'Image')
          ?.map((item) => (
            <PdfSectionLayout _certificateData={item} />
          ))}
        {certificateLayoutData?.filter(
          (d) => d?.data?.length > 0 && d?.type === 'Image'
        )?.length === 0 && (
          <View style={styles.grid2}>
            <Text style={styles.heading2}>Disclaimer:</Text>
            <Text style={styles.footerText}>
              We hereby acknowledge the installation of a GPS/VTS/VLTD device,
              provided by {AppConfig?.fullName} with IMEI No.
              {certificateData?.device?.imei} and model
              {certificateData?.device?.model}, on our vehicle registered under
              number &nbsp;
              {certificateData?.vechicle?.registrationNumber}. Subsequent to the
              installation, we conducted a thorough assessment of the vehicle's
              performance and are pleased to affirm that the device is operating
              in compliance with the standards stipulated in AIS140 by
              ARAI/ICAT. Our satisfaction extends across all operational aspects
              of the unit. We wish to assert our commitment to refrain from
              initiating any disputes or legal claims against{' '}
              {AppConfig?.fullName} in the event that the aforementioned seals,
              device, and harness display any signs of tampering or breakage
              within the 12-month warranty period from the date of installation.
              It is imperative to note that any and all disputes arising shall
              be subjected solely to Kolkata jurisdiction only.*
            </Text>
          </View>
        )}
      </View>
    </Page>
    {certificateLayoutData?.filter(
      (d) => d?.data?.length > 0 && d?.type === 'Image'
    )?.length > 0 && (
      <Page>
        <View style={styles.paper}>
          {certificateLayoutData
            ?.filter((d) => d?.type === 'Image')
            ?.map((item, index) => (
              <PdfImageSectionLayout
                _certificateData={item}
                key={item?.header}
              />
            ))}
          <View style={styles.grid2}>
            <Text style={styles.heading2}>Disclaimer:</Text>
            <Text style={styles.text2}>
              We hereby acknowledge the installation of a GPS/VTS/VLTD device,
              provided by {AppConfig?.fullName} with IMEI No.
              {certificateData?.device?.imei} and model{' '}
              {certificateData?.device?.model}, on our vehicle registered under
              number &nbsp;
              {certificateData?.vechicle?.registrationNumber}. Subsequent to the
              installation, we conducted a thorough assessment of the vehicle's
              performance and are pleased to affirm that the device is operating
              in compliance with the standards stipulated in AIS140 by
              ARAI/ICAT. Our satisfaction extends across all operational aspects
              of the unit. We wish to assert our commitment to refrain from
              initiating any disputes or legal claims against{' '}
              {AppConfig?.fullName} in the event that the aforementioned seals,
              device, and harness display any signs of tampering or breakage
              within the 12-month warranty period from the date of installation.
              It is imperative to note that any and all disputes arising shall
              be subjected solely to Kolkata jurisdiction only. *
            </Text>
          </View>
        </View>
      </Page>
    )}
    {["Customer's", "Department's"].map((copyName) => (
      <Page>
        <View style={styles.paper}>
          <View style={styles.headerLayout}>
            <View style={styles.childComponent}>
              <Image
                style={styles.customImage}
                src={organisationLogo}
                alt="Logo"
              />
            </View>

            <View style={styles.childComponent}>
              <Text style={styles.title}> {copyName} Warranty Certificate</Text>
              {/* <Text
                style={{
                  marginTop: '10px',
                  fontSize: '12px',
                  fontFamily: 'BoldOpenSans',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {copyName} Copy
              </Text> */}
            </View>
            <View style={styles.qrCode}>
              <Image style={styles.customImage} src={qrCodeDataUrl} />
            </View>
          </View>

          {WarrantyLayoutData?.map((item) => (
            <PdfSectionLayout _certificateData={item} key={item?.header} />
          ))}
          <View style={styles.grid2}>
            <Text style={styles.heading2}>Terms for Warranty</Text>

            <Text style={styles.text2}>
              TRAXSMART (herein after called “TRAXSMART”) guarantees to you, the
              original Purchaser, the tracker ”TSINDIA140” (this warranty and
              its clauses apply to all trackers manufactured by TRAXSMART unless
              stated separately ), to be in conformance with the applicable
              TSINDIA140 Specifications current at the time of Manufacture.
              Purchase Date of the First Buyer will be the Warranty Start Date.
              Valid for 12 months for the Tracker and accessories. You must
              inform TRAXSMART immediately of any of the Tracker which has been
              found defective.
            </Text>
          </View>
          <View style={styles.grid2}>
            <Text style={styles.heading2}>CLAIM</Text>
            <Text style={styles.text2}>
              For Claiming the Warranty of the TSINDIA140 Device , In case the
              device is Fitted to Vehicle ,Firstly it should be driven to
              nearest Authorised Repair or Service Centre for analysis of the
              Device after the testing and verification , The device should be
              sent to 21 Princep street 3rd floor Kolkata-700072{' '}
              {certificateData?.owner?.firstName}{' '}
              {certificateData?.owner?.lastName},{' '}
              {certificateData?.owner?.phone}, {certificateData?.owner?.email},
              {certificateData?.owner?.address}, Proof of Purchase Copy incase
              of warranty claim. If the TSINDIA140 device is found faulty before
              the fitment, it should be sent to 21 Princep street 3rd floor
              Kolkata-700072 ( In case of sold ) with{' '}
              {certificateData?.owner?.firstName}{' '}
              {certificateData?.owner?.lastName},{' '}
              {certificateData?.owner?.phone}, {certificateData?.owner?.email},
              {certificateData?.owner?.address}, Proof of Purchase Copy having
              date of Invoice incase of warranty claim.All should be returned
              duly with all packaging and / or accessories as supplied by
              TSINDIA140.
            </Text>
          </View>
        </View>
      </Page>
    ))}
    <Page>
      <View style={styles.paper}>
        <View style={styles.grid2}>
          <Text style={styles.heading2}>
            WHAT IS NOT COVERED BY THE WARRANTY
          </Text>
          <Text style={styles.text2}>
            Warranty are not covered for the defects are due to damage, misuse,
            tampering, neglect or in case of alterations / Changes or repairs
            done by unauthorized persons / Company.
            {'\n'}
            {'\n'}
            The following are Points Not Covered
          </Text>
        </View>
        <View style={styles.listContainer}>
          <Text style={styles.listItem}>
            {' '}
            1) Defects or damage due to abnormal usage or non standard
            environments .
          </Text>
          <Text style={styles.listItem}>
            {' '}
            2) Defects or damage from misuse, accident or intentional damage.
          </Text>
          <Text style={styles.listItem}>
            {' '}
            3) Defects or damage from due to improper testing and usage by non
            authorized applications or operations or modifications done to
            TSINDIA140 device.
          </Text>
          <Text style={styles.listItem}>
            4) Tamper Seal is broken / Box opened by unauthorized person..
          </Text>
          <Text style={styles.listItem}>
            5) Breakage or alterations to Device due to excessive force or other
            means.
          </Text>
          <Text style={styles.listItem}>
            6) TSINDIA140 Device disassembled or repaired other than by
            TRAXSMART .
          </Text>
          <Text style={styles.listItem}>
            {' '}
            7) TSINDIA140 Device exposed to conditions not specified or
            warranted environment or breaching the limits of the stated.
          </Text>
          <Text style={styles.listItem}>
            {' '}
            8) Accessories, software applications and peripherals (specific
            examples include but are not limited to batteries, charges,
            adapters, and power supplies) when such accessories, software
            applications and peripherals are not manufactured and supplied by
            TSINDIA140.
          </Text>
        </View>
        <View style={styles.grid2}>
          <Text style={styles.heading2}>CONDITIONS</Text>
          <Text style={styles.text2}>
            This warranty will not apply if the type or serial numbers on the
            Tracker have been altered, deleted, duplicated, removed or made
            illegible. TSINDIA140 reserves the right to refuse free- of- charge
            warranty service. If the requested documentation cannot be presented
            or if the information is incomplete, illegible or incompatible with
            the factory records. Repair at TSINDIA140's option, may include
            reflashing of software, the replacement of parts or boards with
            functionally equivalent , reconditioned or new parts or boards.
            Replaced parts, accessories, batteries, or boards are warranted for
            the balance of the original warranted tie period. The warranty Term
            will not be extended.
          </Text>

          <Text style={styles.text2}>
            All original accessories, batteries, parts, and tracker equipment
            that have been replaced shall become the property of TSINDIA140.
            TSINDIA140 does not warrant the installation, maintenance or service
            of the products,accessories, batteries, or parts. TSINDIA140 will
            not be responsible in any way for problems or damage caused by any
            ancillary equipment not furnished by TSINDIA140 which is attached to
            or used in connection with the Tracker or for operation of
            TSINDIA140 equipment with any ancillary equipment and all such
            equipment is expressly from this warranty, when the tracker is used
            in conjunction with ancillary or peripheral equipment not supplied
            by TSINDIA140. TSINDIA140 does not warrant the operation of the
            tracker / peripheral combination and TSINDIA140 will not honour any
            warranty claim where the Tracker is used in such a combination and
            it is determined by TSINDIA140 that there is no fault with the
            Tracker. TSINDIA140 specifically disclaims any responsibility for
            any damage, whether or not to TRAXSMART equipment, caused in any way
            by the use of the Tracker,
          </Text>
        </View>

        <br />
        <br />
        <View style={styles.grid2}>
          <Text style={styles.heading2}>
            REPAIRS and SERVICES for out of Warranty
          </Text>
          <Text style={styles.text2}>
            TRAXSMART ,TSINDIA140 will repair tracking device any time after the
            warranty terms of where this warranty does not apply due to the
            defect or fault , then TRAXSMART may at its discretion carry out
            such repairs subject to cost to be borne by Customer as specified by
            TRAXSMART . 21 princep steet 3rd floor Kolkata-700072
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export const handleExportCertificatePdf = (
  certificateData,
  certificateLayoutData,
  WarrantyLayoutData,
  qrCodeDataUrl
) => {
  const pdfDoc = createCertificate(
    certificateData,
    certificateLayoutData,
    WarrantyLayoutData,
    qrCodeDataUrl,
    `/static/images/logos/${AppConfig.id}.png`
  );
  Font.register({
    family: 'BoldOpenSans',
    src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf'
  });
  const asPdf = pdf([]);
  asPdf.updateContainer(pdfDoc);
  asPdf
    .toBlob()
    .then((blob) => {
      saveAs(
        blob,
        `${
          certificateData?.vehicle?.registrationNumber
        }-VLTD_Certificate-${DateTimeService.getDateString(
          certificateData?.device?.fitment?.date
        )}.pdf`
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
